import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useLoginSelector } from "../reducers/login-reducer";
import localSession from "../components/session-manager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import {
faHandshake,
faKey,
faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import config from "../services/config";
import Http from "../services/http";

// load others
import logo from "../assets/images/Movo-Partnership-1.png";
import avatar from "../assets/images/dummy.jpg";
import { clearStore } from "../store/localStorage";

const Avatar = (img) => {
const pic = !img ? avatar : `${config.images}/${img}`;
return pic;
}; //end;

function MainNavbar() {
const location = useLocation();
const user = useLoginSelector((state) => state.loginState.user);
const profile = useLoginSelector((state) => state.loginState.profile);

const logout = async (e) => {
await Http.post("/auth/logout", { user: JSON.stringify(user) });
localSession.clear();
clearStore();
window.location = '/';
}; //end;

useEffect(() => {}, [user, profile]);

return (
<Navbar collapseOnSelect expand="lg" className="fixed-top">
<Container>
<Navbar.Brand as={Link} to="/">
<img src={logo} alt="movo partnership" />
</Navbar.Brand>
<Navbar.Toggle aria-controls="responsive-navbar-nav" />
<Navbar.Collapse id="responsive-navbar-nav" className="profile-mnu">
<Nav className="justify-content-end d-flex align-items-center w-100">
<Nav.Link
as={Link}
to="/howToTradeWithUs"
className={
location.pathname === "/howToTradeWithUs" ? "active" : ""
}
>
How to Trade with us
</Nav.Link>
<Nav.Link
as={Link}
to="/contactus"
className={location.pathname === "/contactus" ? "active" : ""}
>
Contact Us
</Nav.Link>
<NavDropdown
className="d-flex justify-content-center align-items-center avatar-lnk"
// style={{ height: "43px", marginTop: "20px" }}
title={
<div>
{profile.firstName
? `${profile.firstName} ${profile.lastName}`
: user.email}{" "}
<span className="avatar">
{profile && profile.profilePicture ? (
<img
className="thumbnail-image"
src={Avatar(profile.profilePicture.src)}
alt="profile"
/>
) : (
<img
className="thumbnail-image"
src={Avatar(false)}
alt="profile"
/>
)}
</span>
</div>
}
id="collasible-nav-dropdown"
>
<NavDropdown.Item as={Link} to="/profile">
<FontAwesomeIcon icon={faHandshake} /> My Profile
</NavDropdown.Item>
<NavDropdown.Item as={Link} to="/change-password">
<FontAwesomeIcon icon={faKey} /> Change Password
</NavDropdown.Item>
<NavDropdown.Divider />
<NavDropdown.Item onClick={logout}>
<FontAwesomeIcon icon={faSignOutAlt} />
Logout
</NavDropdown.Item>
</NavDropdown>
</Nav>
</Navbar.Collapse>
</Container>
</Navbar>
)} //end;

export default MainNavbar;
