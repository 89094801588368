import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { AlertBox, ToastBox} from '../components/common-components';

const Footer = ()=>{
useEffect(()=>{});
const currentYear = Number(new Date().getFullYear());
const prevYear = Number(currentYear-1);
return (
<>
<ToastBox />
<AlertBox />
<Container fluid className="p-0 mt-4">
<footer className="footer ">
<div  className="p-0 py-4 px-4">&copy; {prevYear}-{currentYear} Movo Partnership - All Rights Reserved.</div>
</footer>
</Container>
</>)};//end;

export default Footer;