import React, { useEffect, useState } from 'react';
import {Alert, Spinner, Toast} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNotificationSelector } from '../reducers/notification-reducer';
import moment from 'moment';
import DatePicker from "react-datepicker";
import localSession from './session-manager';
import $ from 'jquery';


export const Loader = ()=><Spinner animation="grow" variant="secondary" role="status"><span className="visually-hidden">Loading...</span></Spinner>;

export const inputDateFormatter = (getDate)=>moment(getDate).format('YYYY-MM-DD');
export const dateFormatter = (getDate)=>moment(getDate).format('DD/MM/YYYY');

export const DatePickerField = ({ name, value, onChange }) => {
// alert(value)
return (
<DatePicker
name={name}
selected={(value && new Date(value)) || null}
onChange={(val, e) => {
e.persist(); e.stopPropagation();
onChange(name, val);
}}
dateFormat="dd/MM/yyyy"
/>
);
};//end;

export const ApToast = ({msgType='',message="", rest})=>{
const [show, setShow] = useState(true);
const dispatch = useDispatch();
const toggle = ()=>{
setShow(false)
dispatch({type:'set_toast', show: false})
};
return (
<div className="pos-toast">
<Toast 
className={`bg-${msgType.toLowerCase()} `}
onClose={toggle}
show={show} 
delay={5000} 
autohide
>
<Toast.Header>{msgType}</Toast.Header>
<Toast.Body>
{message}
</Toast.Body>
</Toast>
</div>

)
};//endApToast;

export const ToastBox = ()=>{
const toasts = useNotificationSelector(state=>state.notificationState.toasts);

useEffect(()=>{
},[toasts]);
if(toasts.show) return (<ApToast {...toasts} />);
return null;
};//endToastBox;


export const ApAlert = ({msgType,message, rest})=>{
const dispatch = useDispatch();
const toggle = ()=>{
dispatch({type:'set_alert', show: false})
};
return (
<Alert 
variant={msgType.toLowerCase()}
onClose={toggle}
dismissible
>
<Alert.Heading>{msgType}</Alert.Heading>
{message}
</Alert>)
};//endApAlert;

export const AlertBox = ()=>{
const alerts = useNotificationSelector(state=>state.notificationState.alerts);

useEffect(()=>{
},[alerts]);
if(alerts.show) return (<ApAlert {...alerts} />);
return null;
};//endAlertBox;

export const useChangeStatus = async ()=>{
const dispatch = useDispatch();
if(localSession.isAuthor()) {
let result = await localSession.get({user: true, profile: true})
dispatch({ type: 'set_login', success: true, user: result.user, profile: result.profile})
// setWait(false);
};//endif;

};//end;

export const helperSticky = () => {
$(document).ready(()=>{
let $sidebar = $('#sidebar-nav');
// let sidebarTop = $sidebar.position().top;
let sidebarTop = 500;
let blogHeight = $('#content').outerHeight() - 10;
$(window).scroll(fixSidebarOnScroll);
function fixSidebarOnScroll () {
let windowScrollTop = $(window).scrollTop();
if(windowScrollTop >= blogHeight || windowScrollTop <= sidebarTop) {
$sidebar.removeClass('sticky');
} else if(windowScrollTop >= sidebarTop) {
if(!$sidebar.hasClass('sticky')){
$sidebar.addClass('sticky');
};//endif;
};//endif;
};//endFunc;
});//endJquery;
};//endFunc;