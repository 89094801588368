import {useSelector} from 'react-redux'

const initialState = {
success: false,
gallery: false,
}

const galleryState = (state = initialState, { type, ...rest }) => {
switch (type) {
case 'set_gallery':
return {...state, ...rest}
case 'clean_gallery':
return initialState;
default:
return state
}
}

export default galleryState;

export const useGallerySelector = useSelector