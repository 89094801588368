import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Helmet from "react-helmet";

/* load routes */
import { InsurerRoutes } from "../routes";
import { Loader } from "../components/common-components";

function ContentsInsurer() {
return (
<div className="content-area" role="main" aria-label="Content">
<Suspense fallback={<Loader />}>
<Switch>
{InsurerRoutes &&
InsurerRoutes.map((Page, i) => {
if (Page && Page.component) {
const Component = Page.component;
return (
<Route
exect={Page.exect}
path={Page.path}
key={i}
render={(props) => (
<>
<Helmet>
<title>MovoPartnership - {Page.title}</title>
</Helmet>
<Component {...props} />
</>
)}
/>
);
} else {
return null;
}
})}
<Redirect from="/" to="/dashboard" />
</Switch>
</Suspense>
</div>
);
} //end;

export default ContentsInsurer;
