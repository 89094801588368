import { useSelector} from 'react-redux'

const initialState = {
brokers: false,
insurers: false,
};//end;

const statsState = (state = initialState, { type, ...rest }) => {
switch (type) {
case 'set_stats':
return {...state, ...rest }
case 'clean_stats':
return initialState;
default:
return state
}
}

export default statsState;
export const useStatsSelector = useSelector