import { useSelector} from 'react-redux'

const initialState = {
alerts: false,
toasts: false,
};//end;

const notificationState = (state = initialState, { type, ...rest }) => {
switch (type) {
case 'set_alert':
return {...state, alerts: rest }
case 'set_toast':
return {...state, toasts: rest };
case 'clean_notification':
return initialState;
default:
return state
}
}

export default notificationState;
export const useNotificationSelector = useSelector;