export const isJSON = (str: any) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};//end;

export const encode_utf8 = (s) => {
  return unescape(encodeURIComponent(s));
}//end;

export const decode_utf8 = (s) => {
  return decodeURIComponent(escape(s));
};//end;