import {useSelector} from 'react-redux'

const initialState = {}

const brokerState = (state = initialState, { type, ...rest }) => {
switch (type) {
case 'set_broker':
return {...state, ...rest}
case 'clean_broker':
return initialState;
default:
return state
}
}

export default brokerState;

export const useBrokerSelector = useSelector