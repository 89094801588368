import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHandshake,faHome,faShieldAlt,faPhoneAlt,faMapMarkerAlt,faEnvelope,faGlobe,faLink,faCalendarAlt,faUser,faUserFriends,faTachometerAlt,faIcons,faLocationArrow,} from "@fortawesome/free-solid-svg-icons";
import {ListGroup,ListGroupItem,Nav,OverlayTrigger,Tooltip,} from "react-bootstrap";
import {faFacebookF,faInstagram,faLinkedinIn,} from "@fortawesome/free-brands-svg-icons";

import { useLoginSelector } from "../reducers/login-reducer";
import { useAdminSelector } from "../reducers/admin-reducer";
import { useSidebarSelector } from "../reducers/sidebar-reducer";
import { useBrokerSelector } from "../reducers/broker-reducer";
import { useDispatch } from "react-redux";

import conf from "../services/config";
import defaultCompanyLogo from "../assets/images/default_company_logo.jpg";

const Image = (img) => {
const pic = !img ? defaultCompanyLogo : `${conf.images}/${img}`;
return pic;
}; //end;

function AdminSidebar() {
const location = useLocation();
const { editMode } = useSidebarSelector((state) => state.sidebarState);
let isAdmin = location.pathname;
isAdmin = isAdmin.toString().split("/")[1];
if (editMode && location.pathname !== `/dashboard` && isAdmin === "admin")
return <AdminBrokerEditSidebar />;

return (
<>
<div
className="mvp-sidebar sidebar-defaul" role="navigation" aria-label="Sidebar">
<Nav className="flex-column mt-3">
<Nav.Link as={Link} to="/dashboard" className={location.pathname === "/dashboard" ? "active" : ""} >
<FontAwesomeIcon icon={faHome} />Dashboard
</Nav.Link>
<Nav.Link as={Link} to="/insurers" className={location.pathname === "/insurers" ? "active" : ""} >
<FontAwesomeIcon icon={faShieldAlt} />Insurer Management
</Nav.Link>
<Nav.Link as={Link} to="/brokers" className={location.pathname === "/brokers" ? "active" : ""} >
<FontAwesomeIcon icon={faHandshake} />Broker Management
</Nav.Link>
</Nav>
</div>
</>
);
} //endAdminSidebar;

function AdminBrokerEditSidebar () {
const { brokerPageId } = useAdminSelector((state) => state.adminState);

const location = useLocation();
const dispatch = useDispatch();

useEffect(() => {});

return (
<>
<div className="mvp-sidebar sidebar-defaul" role="navigation" aria-label="Sidebar" >
<Nav className="flex-column mt-3">
<Nav.Link as={Link} to="/dashboard" onClick={() => {dispatch({ type: "set_sidebar", editMode: false });}} className={`d-flex justify-content-start`}>
<FontAwesomeIcon icon={faTachometerAlt} />Back to Admin Dashboard
</Nav.Link>

<Nav.Link as={Link} to="/brokers" onClick={() => {dispatch({ type: "set_sidebar", editMode: false });}} className={`d-flex justify-content-start`}>
<FontAwesomeIcon icon={faTachometerAlt} />Back to Brokers
</Nav.Link>

<Nav.Link as={Link} to={`/admin/broker/${brokerPageId}/edit`} className={`d-flex justify-content-start ${location.pathname === "/edit" && "active"} `} >
<FontAwesomeIcon icon={faUser} />Detail Information
</Nav.Link>

<Nav.Link as={Link} to={`/admin/broker/${brokerPageId}/locations`} className={`d-flex justify-content-start ${location.pathname === "/locations" ? "active" : ""} `} >
<FontAwesomeIcon icon={faLocationArrow} />Address
</Nav.Link>

<Nav.Link as={Link} to={`/admin/broker/${brokerPageId}/social`} className={`d-flex justify-content-start ${location.pathname === "/social" ? "active" : ""} `} >
<FontAwesomeIcon icon={faIcons} />Social Profile
</Nav.Link>

<Nav.Link as={Link} to={`/admin/broker/${brokerPageId}/staff`} className={`d-flex justify-content-start ${location.pathname === "/staff" ? "active" : ""}`} >
<FontAwesomeIcon icon={faUserFriends} />Staff Management
</Nav.Link>

<Nav.Link as={Link} to={`/admin/broker/${brokerPageId}/events`} className={`d-flex justify-content-start ${location.pathname === "/events" ? "active" : ""}`} >
<FontAwesomeIcon icon={faCalendarAlt} />Events Photo Gallery
</Nav.Link>
</Nav>
</div>
</>
);
} //endAdminEditBrokerSidebar;

function BrokerSidebar() {
const {companyLogo,website,otherWebsite,facebook,instagram,linkedin,locations,} = useBrokerSelector((state) => state.brokerState);

// const renderTooltipLinkedIn = React.forwardRef((props, ref) => (<Tooltip {...props} innerRef={ref} id="tooltip-linkedin">LinkedIn</Tooltip>));
// const renderTooltipInstagram = React.forwardRef((props, ref) => (<Tooltip {...props} innerRef={ref} id="tooltip-instagram">Instagram</Tooltip>));
// const renderTooltipWebsite = React.forwardRef((props, ref) => (<Tooltip {...props} innerRef={ref} id="tooltip-website">Website Url</Tooltip>));
// const renderTooltipOtherWebsite = React.forwardRef((props, ref) => (<Tooltip {...props} innerRef={ref} id="tooltip-other-website">Portal URL</Tooltip>));

/*const validateUrl = (url)=>{
let rep = url.replace(/^https?:\/\//, '');
return `https://${rep}`;
}*/
return (
<div className="mvp-sidebar sidebar-defaul" role="navigation" aria-label="Sidebar">
<div className="ml-auto mr-auto text-center my-4">{companyLogo ? (<img src={Image(companyLogo)} alt="Company Logo" width="auto" className="img-fluid w-75" />) : (<img src={defaultCompanyLogo} alt="Company Logo" width="auto" className="img-fluid w-75" />)}</div>


<ListGroup horizontal className="justify-content-center align-items-center" >
{facebook  && facebook !== 'None' ? (<ListGroup.Item>
<OverlayTrigger
overlay={<Tooltip id="tooltip-disabled">facebook</Tooltip>}
>
<a href={facebook?facebook:'#'} target="_blank" rel="noreferrer" aria-label="Facebook" role="button">
<FontAwesomeIcon icon={faFacebookF} />
</a>
</OverlayTrigger>
</ListGroup.Item>):''}

{linkedin && linkedin !== 'None' ? (
<ListGroup.Item>
<OverlayTrigger
overlay={<Tooltip id="tooltip-disabled">LinkedIn</Tooltip>}
>
<a href={linkedin?linkedin:'#'} target="_blank" rel="noreferrer" aria-label="Linkedin" role="button">
<FontAwesomeIcon icon={faLinkedinIn} />
</a>
</OverlayTrigger>
</ListGroup.Item>):''}

{instagram  && instagram !== 'None'? (
<ListGroup.Item>
<OverlayTrigger
overlay={<Tooltip id="tooltip-disabled">Instagram</Tooltip>}
>
<a href={instagram?instagram:'#'} target="_blank" rel="noreferrer" aria-label="Instagram" role="button">
<FontAwesomeIcon icon={faInstagram} />
</a>
</OverlayTrigger>
</ListGroup.Item>):''}

{website  && website !== 'None' ? (
<ListGroup.Item>
<OverlayTrigger
overlay={<Tooltip id="tooltip-disabled">Website URL</Tooltip>}
>
<a href={website?website:'#'} target="_blank" rel="noreferrer" aria-label="Website" role="button">
<FontAwesomeIcon icon={faGlobe} />
</a>
</OverlayTrigger>
</ListGroup.Item>):''}

{otherWebsite  && otherWebsite !== 'None'? (
<ListGroup.Item>
<OverlayTrigger
overlay={<Tooltip id="tooltip-disabled">Portal URL</Tooltip>}
>
<a href={otherWebsite?otherWebsite:'#'} target="_blank" rel="noreferrer" aria-label="Other Website" role="button">
<FontAwesomeIcon icon={faLink} />
</a>
</OverlayTrigger>
</ListGroup.Item>):''}

</ListGroup>


<hr></hr>

{locations &&
locations.map((addr, i) => {
return (
<div key={addr + "" + i}>
<ListGroup className="bdr-bm pb-2 pt-2">
<ListGroupItem className="d-flex" aria-label="Address">
<FontAwesomeIcon icon={faMapMarkerAlt} className="custom-m" />
<address>{addr.address}</address>
</ListGroupItem>
<ListGroupItem className="d-flex" aria-label="Contact Number">
<FontAwesomeIcon icon={faPhoneAlt} className="custom-m" />
<span>
<a href={`tel:${addr.phone}`}>{addr.phone}</a>
</span>
</ListGroupItem>
<ListGroupItem className="d-flex">
<FontAwesomeIcon icon={faEnvelope} className="custom-m" />
<span>
<a href={`mailto:${addr.email}`}>{addr.email}</a>
</span>
</ListGroupItem>
</ListGroup>
</div>
);
})}
</div>
);
} //endBrokerSidebar;

function BrokerEditSidebar() {
const location = useLocation();

return (
<>
<div
className="mvp-sidebar sidebar-defaul"
role="navigation"
aria-label="Sidebar"
>
<Nav className="flex-column mt-3">
<Nav.Link
as={Link}
to="/dashboard"
className={`d-flex justify-content-start`}
>
<FontAwesomeIcon icon={faTachometerAlt} />
Broker Dashboard
</Nav.Link>

<Nav.Link
as={Link}
to="/edit"
className={`d-flex justify-content-start ${
location.pathname === "/edit" && "active"
} `}
>
<FontAwesomeIcon icon={faUser} />
Detail Information
</Nav.Link>

<Nav.Link
as={Link}
to="/locations"
className={`d-flex justify-content-start ${
location.pathname === "/locations" ? "active" : ""
} `}
>
<FontAwesomeIcon icon={faLocationArrow} />
Address
</Nav.Link>

<Nav.Link
as={Link}
to="/social"
className={`d-flex justify-content-start ${
location.pathname === "/social" ? "active" : ""
} `}
>
<FontAwesomeIcon icon={faIcons} />
Social Profile
</Nav.Link>
<Nav.Link
as={Link}
to="/staff"
className={`d-flex justify-content-start ${
location.pathname === "/staff" ? "active" : ""
}`}
>
<FontAwesomeIcon icon={faUserFriends} />
Staff Management
</Nav.Link>
<Nav.Link
as={Link}
to="/events"
className={`d-flex justify-content-start ${
location.pathname === "/events" ? "active" : ""
}`}
>
<FontAwesomeIcon icon={faCalendarAlt} />
Events Photo Gallery
</Nav.Link>
</Nav>
</div>
</>
);
} //endEditBrokerSidebar;

const MainSideBarGroup = () => {
const { user } = useLoginSelector((state) => state.loginState);
const location = useLocation();
if (user.role === "admin") return <AdminSidebar />;
if (location.pathname !== "/dashboard") return <BrokerEditSidebar />;
return <BrokerSidebar />;
}; //end;

export default MainSideBarGroup;
