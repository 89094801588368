
const host = window.location.host ? window.location.hostname : 'movo';

export const loadState = () => {
try {
const serializedState = localStorage.getItem(host);
if (serializedState === null) {
return undefined;
}
return JSON.parse(serializedState);
} catch (err) {
return undefined;
}
};//endLoadState;

export const saveState = (state) => {
try {
const serializedState = JSON.stringify(state);
localStorage.setItem(host, serializedState);
} catch {
// ignore write errors
}
};//endSaveState;

export const clearStore = ()=>{
localStorage.removeItem(host);
localStorage.removeItem("adminState");
localStorage.removeItem("statsState");
localStorage.removeItem("loginState");
localStorage.removeItem("notificationState");
localStorage.removeItem("sidebarState");
localStorage.removeItem("brokerState");
localStorage.removeItem("galleryState");
};//end;


export const refreshStore = ()=>{
localStorage.removeItem(host);
localStorage.removeItem("adminState");
localStorage.removeItem("statsState");
localStorage.removeItem("loginState");
localStorage.removeItem("notificationState");
localStorage.removeItem("sidebarState");
localStorage.removeItem("brokerState");
localStorage.removeItem("galleryState");
};//end;

