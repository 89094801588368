import React, { Suspense, useEffect } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import Helmet from 'react-helmet';
import { useLoginSelector } from '../reducers/login-reducer';
import {Loader} from '../components/common-components';

import {AppRoutes} from '../routes';

function MainContent() {
const {user} = useLoginSelector(state=>state.loginState);

useEffect(()=>{
// eslint-disable-next-line
},[]);


    return (
<Suspense fallback={<Loader />} >
<Switch>
{AppRoutes && AppRoutes.map((Page, i)=>{
if(Page && Page.component) {
if(Page.layout===user.role || Page.layout==="common") {} else { return null;};
const Component = Page.component;
return <Route exect={Page.exect} path={Page.path} key={i} 
render={(props)=>(
<>
<Helmet>
<title>
MovoPartnership - {Page.title}
</title>
</Helmet>
<Component {...props} />
</>
)} />
}else{ return null }
})}
<Redirect from="/" to="/dashboard" />
</Switch>
</Suspense>
)};//end;

export default MainContent;