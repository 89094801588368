import { useSelector} from 'react-redux'

const initialState = {
error: false,
success: false,
pending: false,
user: {
userName: "",
email: "",
role: "",
secondaryEmail: "",
isValidSecondaryEmail: "",
phone: "",
isPhoneValid: "",
facebook: "",
twitter: "",
google: "",
},
profile: {
    nameOfBusiness: "",
fullName: "",
firstName: "",
lastName: "",
gender: "",
location: "",
website: "",
picture: "",
},
accessToken: "",
};//end;

const loginState = (state = initialState, { type, ...rest }) => {
switch (type) {
case 'set_login':
let a = {...state};
let b = {...rest};
let update = {...a, ...b}
return update;
case 'clean_login':
return initialState
default:
return state
}
}

export default loginState;
export const useLoginSelector = useSelector