import React, { useEffect, useState } from 'react';
import { useLocation} from 'react-router-dom';

/* load core components */
import MainNavbar from './insurer-navbar';
import localSession from '../components/session-manager';
import InsurerFooter from './insurer-footer';
import ContentsInsurer from './contents-insurer';
import { Container} from 'react-bootstrap';
import SimpleReactLightbox from 'simple-react-lightbox';
import { clearStore } from '../store/localStorage';

import {Loader} from '../components/common-components';


function MainLayout() {
const location = useLocation();
const isBroker = location.pathname.split('/')[1];
const [wait, setWait] = useState(true);

async function verifySessionAndToken (){
const isAuthorSession = (await localSession.isAuthor() && await localSession.isValidToken()) ? true : false;
if(!isAuthorSession) {
localSession.clear();
clearStore();
window.location = '/login';
};//endIf;
setWait(false)
};//end;

useEffect(()=>{
verifySessionAndToken()
// eslint-disable-next-line
})

if(wait) return (<Loader />);

return (
<div className="wrapper pt-5 mt-4" >
<Container className="shadow">
<MainNavbar />
<SimpleReactLightbox>
<ContentsInsurer />
</SimpleReactLightbox>
{isBroker==='broker' ? <InsurerFooter isBroker={true} /> : <InsurerFooter />}
</Container>
</div>
)};//end;


export default MainLayout;