import { combineReducers, createStore} from "redux";
import throttle from 'lodash.throttle';
import {loadState, saveState} from './localStorage';

import adminState from '../reducers/admin-reducer';
import loginState from '../reducers/login-reducer';
import notificationState from '../reducers/notification-reducer';
import sidebarState from '../reducers/sidebar-reducer';
import brokerState from '../reducers/broker-reducer';
import statsState from '../reducers/stats-reducer';
import galleryState from '../reducers/gallery-reducer';

const persistedState = loadState();

const rootReducer = combineReducers({
adminState,
statsState,
loginState,
notificationState,
sidebarState,
brokerState,
galleryState,
});//endRootReducer;

const store = createStore(
rootReducer,
persistedState
);//end;

store.subscribe(throttle(() => {
saveState( {
adminState: store.getState().adminState,
loginState: store.getState().loginState,
notificationState: store.getState().notificationState,
sidebarState: store.getState().sidebarState,
brokerState: store.getState().brokerState,
});
}, 1000));

export default store;