import React from 'react';
import { useEffect, useState } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import Logo from '../../assets/images/logo.png';
import Http from '../../services/http';
import { Loader } from '../../components/common-components';


function ResetPassword () {
const History = useHistory();
const {token} = useParams();
const [passwordChangeStatus, changePasswordStatus] = useState(false);
const [wait, setWait] = useState(true);
const [error, setError] = useState(false);
const [isTokenVerify, changeTokenVerify] = useState(false);
const [isTokenExpire, changeTokenExpire] = useState(false);

async function checkTokenIsValid (){
try{
let {data} = await Http.get(`/auth/verify/${token}`);
if(data.success){
changeTokenVerify(true);
setWait(false);
}else{
changeTokenVerify(false);
changeTokenExpire(data.error);
setWait(false)
};//endIf;
}catch(error){
console.log(error);
}};//end;

let schema = () => Yup.object().shape({
password: Yup.string()
.required("Please enter your password")
.matches(
/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
"Password must contain at least 8 characters, one uppercase, one number and one special case character"
),
confirmPassword: Yup.string()
.required("Please confirm your password")
.when("password", {
is: (password) => (password && password.length > 0 ? true : false),
then: Yup.string().oneOf(
[Yup.ref("password")],
"Password doesn't match"
),
}),
});//end;

useEffect(()=>{
window.document.title = `MovoPartnership - Reset Password`;
if(!isTokenVerify && !isTokenExpire) checkTokenIsValid();
// eslint-disable-next-line
},[isTokenVerify]);

if(wait) return (<Loader />);

if(isTokenExpire) return (

<div className="wrapper login-bg">
<div className="mvector"></div>
<img src={Logo} alt="mobo partnership" className="img-index" />
<div className="container">
<div className="row align-items-center justify-content-center">

<div className="login-box mt-4 col-12">
<Card>
<CardHeader>Sorry</CardHeader>
<CardBody className="text-center">
<CardTitle>
{isTokenExpire}
</CardTitle>
<Button type="button" className="btn-bdr"
onClick={(ev)=>{
window.location = '/';
}}>
Back to Login
</Button>
</CardBody>
</Card>

</div>

</div>
</div></div>

);//end;

if(passwordChangeStatus) return (

<div className="wrapper login-bg">
<div className="mvector"></div>
<img src={Logo} alt="mobo partnership" className="img-index" />
<div className="container-fluid">
<div className="row align-items-center justify-content-center">
<div className="login-box mt-4 col-12">


<Col>
<Card>
<CardHeader className="text-center">Password has been changed successfully.</CardHeader>
<CardBody className="text-center">
<Button type="button" className="btn-bdr"
onClick={(ev)=>{
window.location.href='/';
}}>
Go to Login
</Button>
</CardBody>
</Card>
</Col>

</div>
</div>
</div>
</div>
);//end;

return (

<div className="wrapper login-bg">
<div className="mvector"></div>
<img src={Logo} alt="mobo partnership" className="img-index" />
<div className="container-fluid">
<div className="row align-items-center justify-content-center">

<div className="login-box mt-4 col-12">

<Formik
initialValues={{
password: '',
confirmPassword: ''
}}
validationSchema={schema}
onSubmit={async (values, { setSubmitting, setFieldError }) => {
setSubmitting(true);
try {
const body = {
password: values.password.trim(),
confirmPassword: values.confirmPassword.trim(),
};
let {data} = await Http.post(`/auth/reset/${token}`, body);
if(data.success){
changePasswordStatus(true)
}else{
setError(data.error);
alert(JSON.stringify(data,null,2))
};//endif;
setSubmitting(false);
// History.goBack()
} catch (error) {
let {response, request} = error;
if(response) {
if(response.data.errors){
for(let x of response.data.errors){
setFieldError(x.param, x.msg)
};//endFor;
}else if(response.data.error){
setError(response.data.error);
// alert(JSON.stringify(response))
};//endIf;
// alert(JSON.stringify(response.data,null,2))
}else if(request) {
console.log(error);
};//endIf;
console.log(error);
setSubmitting(false);
}
setSubmitting(false);
}}
>
{({values,errors,touched,status,dirty,handleChange,handleBlur,handleSubmit,isSubmitting,isValid,handleReset,setTouched,}) => (
<Form onSubmit={handleSubmit} noValidate>
{error ? (<Col>Error: {error}</Col>):""}
<FormGroup row className="mb-3">
<Label for="Password" sm={5}>Password: </Label>
<Col sm={7}>
<Input
type="password"
placeholder="Enter new password"
id="Password"
name="password"
valid={!errors.password}
invalid={touched.password && !!errors.password}
value={values.password}
onChange={handleChange}
onBlur={handleBlur}
autoFocus={true}
required
/>
<FormFeedback>{errors.password}</FormFeedback>
</Col>
</FormGroup>

<FormGroup row>
<Label for="ConfirmPassword" sm={5}>Confirm Password: </Label>
<Col sm={7}>
<Input
type="password"
placeholder="Enter Confirm password"
id="ConfirmPassword"
name="confirmPassword"
valid={!errors.confirmPassword}
invalid={
touched.confirmPassword && !!errors.confirmPassword
}
value={values.confirmPassword}
onChange={handleChange}
onBlur={handleBlur}
required
/>
<FormFeedback>{errors.confirmPassword}</FormFeedback>
</Col>
</FormGroup>



<FormGroup row className="mt-3">
<Label for="confirmPassword" sm={5}>
</Label>
<Col sm={7}>
<Button type="submit" className="btn-bdr" >Submit</Button>
<Button type="button" className="btn-bdr" onClick={(ev)=> History.replace("/")} >Back to Login</Button>
</Col>
</FormGroup>
</Form>
)}
</Formik>

</div>
</div>
</div>
</div>

)};//end;

export default ResetPassword;