import { lazy } from "react";

/**
* load all common components
*/
const Dashboard = lazy(() => import(`./views/dashboard/admin`));
const ProfileComponent = lazy(() => import(`./components/profile-component`));
const TradePage = lazy(() => import("./views/trade/trade-with-us-page"));
const ContactUs = lazy(() => import("./views/contact-us/contact-us"));
const ChangePassword = lazy(() => import("./views/change-password/change-password"));

/**
* load all admin components
*/
const BrokerList = lazy(() => import(`./views/admin/broker-list`));
const BrokerRegister = lazy(() => import(`./views/admin/broker-register`));
const InsurerList = lazy(() => import(`./views/admin/insurer-list`));
const InsurerRegister = lazy(() => import(`./views/admin/insurer-register`));
const AdminBroker = lazy(() => import(`./views/dashboard/admin-broker`));

/**
* load Broker pages and components
*/
const BrokerDashboard = lazy(() => import(`./views/dashboard/broker`));
const EditBrokerDashboard = lazy(() =>import(`./views/broker/broker-dashboard-edit`));
const Social = lazy(() => import(`./views/broker/social`));
const Staff = lazy(() => import(`./views/broker/staff`));
const AddStaff = lazy(() => import(`./views/broker/add-staff`));
const EventsGallery = lazy(() => import(`./views/broker/events-gallery`));
const Events = lazy(() => import(`./views/broker/events`));
const AddEvent = lazy(() => import(`./views/broker/add-event`));

const Locations = lazy(() => import(`./views/broker/locations`));

/**
* load Insurer page and components
*/
const InsurerDashboard = lazy(() => import(`./views/dashboard/insurer`));
const InsurerBroker = lazy(() => import(`./views/insurer/broker`));
const InsurerBrokerEventsGallery = lazy(() =>import(`./views/insurer/events-gallery`));

/* Common Routes */
export const CommonRoutes = [
{ name: "Admin", path: "/dashboard", layout: "admin", component: Dashboard, title: "Admin", },
{ name: "Broker", path: "/dashboard", layout: "broker", component: BrokerDashboard, title: "Broker", },
{ name: "Insurer", path: "/dashboard", layout: "insurer", component: InsurerDashboard, title: "Insurer", },
{ name: "HowToTradeWithUs", path: "/howToTradeWithUs", layout: "common", component: TradePage, title: "How to Trade with us", },
{ name: "MyProfile", path: "/profile", layout: "common", component: ProfileComponent, title: "My Profile", },
{ name: "ContactUs", path: "/contactus", layout: "common", component: ContactUs, title: "Contact us", },
{ name: "ChangePassword", path: "/change-password", layout: "common", component: ChangePassword, title: "Change Password", },
]; //end;CommonRoutes;

/* Admin Routes */
export const AdminRoutes = [
{ name: "AdminBrokerAddStaff", path: "/admin/broker/:id/staff/add", layout: "admin", component: AddStaff, title: "Admin - Broker Add New Staff", },
{ name: "AdminBrokerStaff", path: "/admin/broker/:id/staff", layout: "admin", component: Staff, title: "Admin - Broker Staff", },
{ name: "AdminBrokerEventsGallery", path: "/admin/broker/:id/events/gallery", layout: "admin", component: EventsGallery, title: "Admin - Broker Events Gallery", },
{ name: "AdminBrokerAddEvent", path: "/admin/broker/:id/events/add", layout: "admin", component: AddEvent, title: "Admin - Broker Add New Event", },
{ name: "AdminBrokerEvents", path: "/admin/broker/:id/events", layout: "admin", component: Events, title: "Admin - Broker Events", },
{ name: "AdminBrokerSocialLinks", path: "/admin/broker/:id/social", layout: "admin", component: Social, title: "Admin - Broker Social Links", },
{ name: "AdminBrokerLocations", path: "/admin/broker/:id/locations", layout: "admin", component: Locations, title: "Admin - broker Branch or Offices Locations/Address", },
{ name: "AdminBrokerEdit", path: "/admin/broker/:id/edit", layout: "admin", component: EditBrokerDashboard, title: "Admin - Broker Edit", },
{ name: "AdminBrokerDashboard", path: "/admin/broker/:id", layout: "admin", component: AdminBroker, title: "Admin - Broker", },
{ name: "BrokerRegister", path: "/brokers/register", layout: "admin", component: BrokerRegister, title: "Admin - Register Broker", },
{ name: "BrokerList", path: "/brokers", layout: "admin", component: BrokerList, title: "Admin - All Brokers", },
{ name: "InsurerRegister", path: "/insurers/register", layout: "admin", component: InsurerRegister, title: "Admin - Register Insurer", },
{ name: "InsurerList", path: "/insurers", layout: "admin", component: InsurerList, title: "Admin - All Insurers", },
]; //endAdminRoutes;

/* Broker Routes */
export const BrokerRoutes = [
{ name: "Locations", path: "/locations", layout: "broker", component: Locations, title: "broker Branch or Offices Locations/Address", },
{ name: "Edit", path: "/edit", layout: "broker", component: EditBrokerDashboard, title: "edit Information", },
{ name: "Social", path: "/social", layout: "broker", component: Social, title: "Social Links", },
{ name: "AddStaff", path: "/staff/add", layout: "broker", component: AddStaff, title: "Add Staff", },
{ name: "OurStaff", path: "/staff", layout: "broker", component: Staff, title: "Our Staff", },
{ name: "EventsGallery", path: "/events/gallery", layout: "broker", component: EventsGallery, title: "Events Gallery", },
{ name: "OurEvents", path: "/events", layout: "broker", component: Events, title: "Our Events", },
{ name: "AddEvent", path: "/events/add", layout: "broker", component: AddEvent, title: "Add Event", },
]; //endBrokerRoutes;

export const InsurerRoutes = [
{ name: "Home", exect: true, path: "/", title: "Home",},
{ name: "Insurer", path: "/dashboard", layout: "insurer", component: InsurerDashboard, title: "Insurer", },
...CommonRoutes,
{ name: "InsurerBrokerEventsGallery", path: "/broker/:id/gallery", layout: "broker", component: InsurerBrokerEventsGallery, title: "Insurer - Broker Latest Events Gallery", },
{ name: "InsurerBroker", path: "/broker/:id", layout: "broker", component: InsurerBroker, title: "Insurer - Broker Detail", },
]; //endInsurerRoutes;

export const AppRoutes = [
{ name: "Home", exect: true, path: "/", title: "Home",},
...CommonRoutes,
...AdminRoutes,
...BrokerRoutes,
]; //endAppRoutes;
