import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable' // comment by bob temp

import React from 'react';
import {render} from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import 'moment-timezone';
// import * as serviceWorker from './serviceWorker';

//bootstrap 
import "bootstrap/dist/css/bootstrap.min.css";

//Datatable Modules  
import "datatables.net-dt/css/jquery.dataTables.min.css"  

// custom css
import './assets/css/style.css';
import "./assets/css/responsive.css"

//date picker 
import "react-datepicker/dist/react-datepicker.css";

// jQuery
import $ from 'jquery';

import 'bootstrap/dist/js/bootstrap.bundle'; // commint by bob temp
import "datatables.net-dt/js/dataTables.dataTables"  
import "datatables.net-dt/css/jquery.dataTables.min.css"

/* responsive */
import 'datatables.net-responsive-dt'

window.jQuery = $;

// import "https://cdn.datatables.net/responsive/2.2.9/css/responsive.dataTables.min.css"
// import "https://cdn.datatables.net/responsive/2.2.9/js/dataTables.responsive.min.js"
// import "https://cdn.datatables.net/responsive/2.2.9/css/responsive.bootstrap4.min.css"
// import 'https://cdn.datatables.net/responsive/2.2.9/js/dataTables.responsive.min.js'
// import 'https://cdn.datatables.net/responsive/2.2.9/js/responsive.bootstrap4.min.js' 

const root = document.getElementById('root');

render(
<BrowserRouter>
<Provider store={store} >
<App />
</Provider>
</BrowserRouter>
,root );



// services worker
// serviceWorker.unregister();
// serviceWorker.register();

