import React, { Suspense, useEffect} from "react";
import {BrowserRouter as Router,Route,Switch,Redirect,useLocation} from "react-router-dom";
import { useLoginSelector } from "./reducers/login-reducer";
import { Loader } from "./components/common-components";

/* load Layouts  */
import DefaultLayout from "./layout/default-layout";
import InsurerLayout from "./layout/insurer-layout";
// const DefaultLayoutNoSidebar = lazy(() =>import(`./layout/default-layout-no-sidebar`));

/* load pages  */
import LoginPage from "./views/login/login";

import ForgotPassword from "./views/forgot/forgot-password";
import ResetPassword from "./views/reset-password/reset-password";


function App() {
const { user} = useLoginSelector((state) => state.loginState);
const location = useLocation();

useEffect(()=>{
// eslint-disable-next-line
},[location.pathname]);

return (
<Suspense fallback={Loader}>
<Router>
<Switch>
<Route exect path="/reset/:token" component={ResetPassword} />
<Route exect path="/forgot" component={ForgotPassword} />
<Route exect path="/login" component={LoginPage} />
<Route exect path="/profile" component={InsurerLayout} />
<Route exect path="/howToTradeWithUs" component={InsurerLayout} />
<Route exect path="/contactus" component={InsurerLayout} />
{user.role === "insurer" && (<Route path="/" component={InsurerLayout} />)}
<Route path="/" component={DefaultLayout} />
<Redirect from="/" to="/login" />
</Switch>
</Router>
</Suspense>
);
};//end;

export default App;
