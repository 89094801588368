import Cookies from 'js-cookie';
import Http from '../services/http';
const host = window.location.hostname;

// Cookies.set('authorization', accessToken, { expires: 1, path:'/', SameSite:'Lax'})

export default class localSession {
static isValidToken = async ()=>{
return await Http.get(`/api/token`)
.then((res)=>{
return res.data.success;
}).catch((err)=>{
return false;
})
// return data;
};//end;

static isAuthor = ()=>{
let status = Cookies.get('authorization') ? true : false;
return status;
};//end;

static get = (value)=>{
const {profile, user} = value;
let result = {};
if(user) result.user = JSON.parse(Cookies.get(host+'_user'))
if(profile) result.profile = JSON.parse(Cookies.get(host+'_profile'))
return result;
};//end;

static set = (value)=>{
const {profile, user, accessToken} = value;
let oneDayToSeconds = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);
if(accessToken) Cookies.set('authorization', accessToken, { expires: oneDayToSeconds, path:'/', SameSite:'Lax', domain: host})
if(user) Cookies.set(host+'_user', JSON.stringify(user), { expires: oneDayToSeconds, path:'/', SameSite:'Lax', domain: host})
if(profile) Cookies.set(host+'_profile', JSON.stringify(profile), { expires: oneDayToSeconds, path:'/', SameSite:'Lax', domain: host})
return true;
};//end;

static clear = ()=>{
Cookies.remove(host+'_user', {  path:'/', domain: host});
Cookies.remove(host+'_profile', {  path:'/', domain: host});
Cookies.remove('authorization', {  path:'/', domain: host})
window.localStorage.clear();
return true;
};//end;

};//end;