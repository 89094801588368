import React, { useEffect } from 'react';
import { AlertBox, ToastBox} from '../components/common-components';

const Footer = ({isBroker})=>{
let styles = {};
const currentYear = Number(new Date().getFullYear());
const prevYear = Number(currentYear-1);

useEffect(()=>{});

if(isBroker) return (
<div className="p-0 mt-4" style={styles}>
    <footer className="footer footer-mr-l">
<div  className="p-0 py-4 px-4">&copy; {prevYear}-{currentYear} Movo Partnership - All Rights Reserved.</div>
</footer>
</div>
);//end;

return (
<>
<ToastBox />
<AlertBox />

<div className="p-0 mt-4" style={styles}>
<footer className="footer">
<div  className="p-0 py-4 px-4">&copy; {prevYear}-{currentYear} Movo Partnership - All Rights Reserved.</div>
</footer>
</div>

</>)};//end;

export default Footer;