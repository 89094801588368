import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
// import * as Yup from 'yup';

import { useDispatch } from 'react-redux';
import localSession from '../../components/session-manager';
import { clearStore } from "../../store/localStorage";

import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import Logo from '../../assets/images/logo.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUser, faLock, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Http from '../../services/http';
import { Loader } from '../../components/common-components';

function LoginPage() {
const dispatch = useDispatch();
const [loginErrors, setLoginErrors] = useState('');
const [loginError, setLoginError] = useState('');
const [show, setShow] = useState(false);
const [wait, setWait] = useState(true);

async function loginVerifyToken(){
const isAuthorSession = (localSession.isAuthor() && localSession.isValidToken()) ? true : false;
if(isAuthorSession) {
// window.location = '/dashboard';
}else{
localSession.clear();
clearStore();
// alert('clear ho gaya')
};//endIf;
setWait(false);
};//end;

useEffect(()=>{
window.document.title = `Welcome MovoPartnership - login`;
loginVerifyToken()
});//end;

if(wait) return (<Loader />);

return (
<div className="wrapper login-bg">
<div className="mvector"></div>
<img src={Logo} alt="mobo partnership" className="img-index" />
<div className="container-fluid">
<div className="row align-items-center justify-content-center">
<Formik
initialValues={{
    userName: '', 
    password: ''
    }}
// validationSchema={Yup.object().shape({
// userName: Yup.string().min(5, `Username has to be at least 5 characters`).required('Username is required'),
// password: Yup.string().min(8, `Password has to be at least ${8} characters!`).matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, 'Password must contain: numbers, uppercase and lowercase letters\n').required('Password is required'),
// })}
onSubmit={ async (values, { setSubmitting, setErrors }) =>{
try{
const body = {
    email: values.userName.toLowerCase().trim(), 
    password: values.password.trim()
    };
const {data} = await Http.post('/auth/login',body);
const {profile, user, accessToken} = data;
localSession.set({profile, user, accessToken});
dispatch({ type: 'set_login', success: true, pending: false, profile, user});
window.location = '/';
}catch(error){
let { response, request} = error;
if(response){
let {data} = response;
if(data.errors) {
setLoginErrors(data.errors);
};//endif;
if(data.error){
setLoginError(data.error);
};//endif;
setShow(true);
}else if(request) {
console.log(request);
};//endif;
setSubmitting(false);
}}}>
{({values, errors, touched, status, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, handleReset, setTouched}) => (
    <div className="login-box mt-4 col-12">
<h1 className="mb-4 text-center">User Login</h1>
{show && (
<Alert variant="danger" onClose={() => setShow(false)} dismissible
// style={styles.alertFixed}
>

{loginError && (<p><FontAwesomeIcon icon={faInfoCircle}/> {loginError}</p>)}
{loginErrors && loginErrors.map((err, i)=>{ return (<p key={i}>{err}</p>) })}
</Alert>
)}
<Form onSubmit={handleSubmit} noValidate name='LoginForm' className="row">
<div className="col-12 mb-3">
<label htmlFor="UserName" className="form-label ">User Name</label>
<div className="input-group">
<div className="input-group-text"><FontAwesomeIcon icon={faUser}/> </div>
<input
type="text" 
className="form-control line-input" 
name="userName"
id="UserName"
placeholder="Type your user name" 
// valid={!errors.userName}
required
onChange={handleChange}
// onBlur={handleBlur}
autoFocus={true}
value={values.userName} />
</div>
{touched.userName ?
<Form.Control.Feedback type="inValid" >{errors.userName}</Form.Control.Feedback>
: ''}
</div>

<div className="col-12">
<label htmlFor="inputPassword4" className="form-label">Password</label>
<div className="input-group">
<div className="input-group-text"><FontAwesomeIcon icon={faLock}/></div>
<input 
type="password" 
className="form-control line-input" 
id="inputPassword4" 
name="password"
placeholder="Type your password" 
// valid={!errors.password}
required
onChange={handleChange}
// onBlur={handleBlur}
value={values.password} />
</div>
{touched ? <Form.Control.Feedback type="inValid" >{errors.password}</Form.Control.Feedback> : ""}
</div>
<div className="text-right d-flex justify-content-end">
    {/* <Button  className="txt-lnk">Forgot password?</Button> */}
<Link to="/forgot" className="txt-lnk mt-1">Forgot password?</Link>
</div>
<div className="col-12 mt-4">
{!isSubmitting ? (<Button className="movop-btn" type="submit" >Login</Button>)
: (<Button>Process... <Spinner color="primary" /> </Button>)}
</div>
</Form>
</div>
)}
</Formik>
</div>
</div>

</div>
)};//end;


export default LoginPage;