import {useSelector} from 'react-redux'

const initialState = {
sidebarShow: true,
editMode: false
}

const sidebarState = (state = initialState, { type, ...rest }: themeArgs) => {
switch (type) {
case 'set_sidebar':
return {...state, ...rest}
case 'clean_sidebar':
return initialState;
default:
return state
}
}

export default sidebarState;

export const useSidebarSelector = useSelector