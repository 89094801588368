import axios from "axios";
import config from "./config";
import { isJSON } from "./common";
import Cookies from 'js-cookie';

const AUTH_TOKEN = Cookies.get('authorization');
axios.defaults.baseURL = config.api_url;
axios.defaults.headers.common.Authorization = AUTH_TOKEN;
// axios.defaults.headers.common.time = new Date().getTime();
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export default class Http {
static get = (endpoint) => {
const axiosConfig = {
headers: {
"Content-Type": "application/json;charset=UTF-8",
"time": new Date().getTime(),
},
};//endConfig;

const request = axios.get(endpoint
, axiosConfig);
return request
.then((result) => {
const data = isJSON(result.data) ? JSON.parse(result.data) : result.data;
if (result.status === 200 && data.ErrorMessage) {
throw new Error(data.ErrorMessage);
};//endif;
return { ...result, data };
}).catch((error) => Promise.reject(error));
};//endGet;

static post = (endpoint, data) => {
const axiosConfig = {
headers: {
"Content-Type": "application/json;charset=UTF-8",
// "X-Requested-With": "XMLHttpRequest",
// VerificationToken: Cookies.get('accessToken'),
},
};//endConfig;
const request = axios.post(endpoint, data, axiosConfig);
return request.then((res) => {
const data = isJSON(res.data) ? JSON.parse(res.data) : res.data;
if (res?.status === 200 && data?.ErrorMessage) {
}
return { ...res, data };
}).catch((error) => Promise.reject(error));
};//endPost;

};//endHttp;

