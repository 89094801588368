import React, { useEffect, useState } from 'react';
import {useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';

import { Button, Form, Spinner, Toast} from 'react-bootstrap';
import Logo from '../../assets/images/logo.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Http from '../../services/http';



function ForgotPassword () {
const History = useHistory();
const [msg, setMsg] = useState(false);
const [show, setShow] = useState(false);

useEffect(()=>{
window.document.title = `MovoPartnership - Forgot Password`;
});

return (
<div className="wrapper login-bg">
<div className="mvector"></div>
<img src={Logo} alt="mobo partnership" className="img-index" />
<div className="container-fluid">
<div className="row align-items-center justify-content-center">
<Formik
initialValues={{
email: '', 
}}
validationSchema={Yup.object().shape({
email: Yup.string().min(5, `Email has to be at least 5 characters`).required('Email is required'),
})}
onSubmit={ async (values, { setSubmitting, setFieldError }) =>{
try{
const body = {
email: values.email.toLowerCase().trim(), 
};
const {data} = await Http.post('/auth/forgot',body);
if(data.success){
// alert(JSON.stringify(data,null,2))
setMsg(data.msg);
setShow(true)
};//endIf;
setSubmitting(false);
}catch(error){
let { response, request} = error;
if(response){
let {data} = response;
if(data.error) setFieldError('email', data.error)
if(data.errors) setFieldError('email', data.errors[0].msg)
}else if(request) {
console.log(request)
};//endif;
setSubmitting(false);
}}}>
{({values, errors, touched, status, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, handleReset, setTouched}) => (
<div className="login-box mt-4 col-12">
<h1 className="mb-2 text-center">Forgot your password?</h1>
<div className="text-center mb-2">Enter your email address and we'll send you a link to reset your password.</div>
<Form onSubmit={handleSubmit} noValidate name='LoginForm' className="row">

<div className="col-12 mb-3">
<label htmlFor="UserName" className="form-Label ">Email Address</label>
<div className="input-group">
<div className="input-group-text"><FontAwesomeIcon icon={faEnvelope}/> </div>
<input
type="text" 
className="form-control line-input" 
name="email"
id="UserName"
placeholder="Your email address" 
// valid={!errors.email}
required
onChange={handleChange}
onBlur={handleBlur}
autoFocus={true}
value={values.email} 
/>
</div>
{touched.email ?
<Form.Control.Feedback type="inValid" >{errors.email}</Form.Control.Feedback>
: ''}
</div>
<div className="col-12 text-center">
{!isSubmitting ? (<Button className="movop-btn" type="submit">Request reset link</Button>) : (<Button className="btn-bdr"><Spinner as="span" animation="border" variant="dark" size="sm" /> Process... </Button>)}
{!isSubmitting ? (<Button type="button" className="btn-bdr mt-2" onClick={(ev)=>{
History.goBack();
}} >Back to login</Button>) : ""}
</div>
</Form>
</div>
)}
</Formik>


<Toast className="bg-error" style={{position:'absolute', top:'20px', right:'20px', zIndex:'11', padding:'0px'}}
onClose={() => setShow(false)} 
show={show} 
delay={15000} 
autohide
>
<Toast.Header>   
<strong className="me-auto">Success</strong>    
</Toast.Header>
<Toast.Body>
{msg}
</Toast.Body>
</Toast>




</div>
</div>

</div>




)};//end;



export default ForgotPassword;