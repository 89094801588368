import React from 'react';
import { useEffect, useState } from 'react';
import { clearStore } from '../store/localStorage';

/* load core components */
import Sidebar from './sidebar';
import MainContent from './contents';
import MainNavbar from './navbar';
import localSession from '../components/session-manager';
import Footer from './footer';
import SimpleReactLightbox from 'simple-react-lightbox';

import {Loader} from '../components/common-components';
import { useLocation } from 'react-router';


function DefaultLayout() {
const [wait, setWait] = useState(true);
const location = useLocation();

async function verifySessionAndToken (){
const isAuthorSession = (await localSession.isAuthor() && await localSession.isValidToken()) ? true : false;
if(!isAuthorSession) {
localSession.clear();
clearStore();
window.location = '/login';
};//endIf;
setWait(false)
};//end;

useEffect(()=>{
verifySessionAndToken()
},[location.pathname]);

if(wait) return (<Loader />);

return (
<div className="wrapper">
<MainNavbar />
<Sidebar />
<div className={`content-page`} role="main" aria-label="content">
<div className="content-area">
<SimpleReactLightbox>
<MainContent />
</SimpleReactLightbox>
</div>
<Footer />
</div>
</div>
)};//end;

export default DefaultLayout;