import {useSelector} from 'react-redux'

const initialState = {};

const adminState = (state = initialState, { type, ...rest }) => {
switch (type) {
case 'set_admin':
return {...state, ...rest}
case 'clean_admin':
return initialState;
default:
return state
}
}

export default adminState;

export const useAdminSelector = useSelector