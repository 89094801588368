
export const isProduction = (process.env.REACT_APP_MODE === "production") ? true : false;
export const api_url = (isProduction) ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_LOCAL_BASE_URL;


export const config = {
api_url: api_url,
images: `${api_url}${isProduction ? "/uploads" : ""}/images`,
pageImages: `${api_url}${isProduction ? "/uploads" : ""}/pages`,
pdfImages: `${api_url}${isProduction ? "/pdf" : "/pdf"}/images`,
};//end;

export default config;